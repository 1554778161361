import { InstantSearchState, initialState } from "./state";

import { createContext } from "react";

export interface InstantSearchContextInterface extends InstantSearchState {
  setCarryOverFilter: (filter: string) => void;
  currentFilterGroup?: string;
  toggleFilterGroup?: (id: string) => void;
}

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <InstantSearchProvider>"
  );
};

export const InstantSearchContext = createContext<
  InstantSearchContextInterface
>(initialState as InstantSearchContextInterface);
