export interface InstantSearchState {
  error?: Error;
  carryOverFilter?: string;
  tierFilter?: string | undefined;
}

export const initialState: InstantSearchState = {
  carryOverFilter: "",
  tierFilter: "",
};
