import { TrackingEventName } from "./event-tracking";

declare global {
  interface Window {
    analytics: any;
  }
}

export const trackEvent = (eventName: TrackingEventName, events: any): void => {
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, events);
  }
};
