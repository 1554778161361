const PREVIEW_PATHS = ["/offer-preview", "/event-preview"];

export const decodePreviewCode = (code: string) => {
  try {
    if (!code.match(/^[A-Za-z0-9+/=]+$/)) {
      return { error: "Invalid preview code format" };
    }

    const paddedCode = code.padEnd(
      code.length + ((4 - (code.length % 4)) % 4),
      "="
    );

    const decodedString = atob(paddedCode);
    const [email, timestamp, id, tier, stockStatus] = decodedString.split("|");
    return { email, timestamp, id, tier, stockStatus };
  } catch (error) {
    console.error("Error decoding preview code:", error);
    return { error: "Invalid preview code format" };
  }
};

export const validatePreviewCode = (previewCode: string) => {
  const decoded = decodePreviewCode(previewCode);

  if (decoded?.error) {
    return { error: decoded.error };
  }

  const { email, timestamp, id, stockStatus } = decoded;

  if (!id) {
    return { error: "Invalid preview code." };
  }

  if (!email?.endsWith("@vinomofo.com")) {
    return { error: "Invalid preview code." };
  }

  const currentTime = Date.now() / 1000;
  const timeLimit = 1 * 60 * 60;
  if (currentTime - Number(timestamp) > timeLimit) {
    return {
      error: "Preview link has expired. Please request a new preview link.",
    };
  }

  const validStockStatuses = ["PRESELLING", "AVAILABLE", "SOLD_OUT"];
  if (stockStatus && !validStockStatuses.includes(stockStatus)) {
    return { error: "Invalid stock status provided." };
  }

  return { isValid: true, data: decoded };
};

export const hasPreviewPath = (pathname: string) => {
  return PREVIEW_PATHS.some((path) => pathname.includes(path));
};
