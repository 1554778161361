import * as React from "react";

import {
  ALL_FACETS,
  FilterOnlyFacets,
  MultiRangeFacets,
  RefinementFacets,
} from "./refinements";
import { InstantSearchContext, InstantSearchContextInterface } from "./context";

import { InstantSearch } from "react-instantsearch";
import { VinoUser } from "../../contexts/auth/state";
import { algoliasearch } from "algoliasearch";
import { determineTierIndex } from "../../utils/loyalty";
import { history } from "instantsearch.js/es/lib/routers";
import { initialState } from "./state";
import isBrowser from "../../utils/is-browser";
import { navigate } from "gatsby";
import qs from "qs";
import { trackOfferFilterEvent } from "../../utils/event-tracking";
import useAuth from "../../hooks/use-auth";
import { useLocation } from "@reach/router";
import { useLoyalty } from "../../hooks/account/use-loyalty";
import { useState } from "react";

export interface InstantSearchProviderProps {
  children: React.ReactNode;
  isFeatured?: boolean;
}

export const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const getSortSlug = (value: string): string | null => {
  const regex = /price_(asc|desc)$/;
  return regex.test(value) ? value.match(regex)![0] : null;
};

const getSortValue = (sortOrder: string, baseIndex: string): string => {
  return sortOrder === "price_desc" || sortOrder === "price_asc"
    ? `${baseIndex}_${sortOrder}`
    : baseIndex;
};

const noRefinements = (refinementList): boolean =>
  !refinementList ||
  ALL_FACETS.every(({ attribute }) => refinementList[attribute]?.length === 0);

const noSorting = (sortBy, index): boolean => !sortBy || sortBy === index;

const isDefaultRoute = (state): boolean =>
  !state.query &&
  !state.toggle &&
  state.page === 1 &&
  noRefinements(state.refinementList) &&
  noSorting(state.sortBy);

type FilterQueryParams = {
  query?: string;
  keywords?: string;
  sortBy?: string | null;
  toggle?: { [key: string]: string };
  page?: number;
};

const WHISKYMOFO_HEADER_ENABLED =
  process.env.GATSBY_ENABLE_WHISKYMOFO_HEADER === "true";

export const InstantSearchProvider = ({
  children,
  isFeatured = false,
}: InstantSearchProviderProps): JSX.Element => {
  const LOCATION_ = useLocation();
  const { user } = useAuth();
  const { loyalty, showLoyalty } = useLoyalty();
  const { index, tierFilter } = determineTierIndex(
    user as VinoUser,
    showLoyalty,
    loyalty?.loyalty_tier
  );

  const [carryOverFilter, setCarryOverFilter] = useState<string>(
    LOCATION_?.search.replace(/query=[^&]+&?/, "")
  );
  const [currentFilterGroup, setCurrentFilterGroup] = useState("");
  const toggleFilterGroup = (id: string) => {
    setCurrentFilterGroup(id);
  };

  const createURL = (location, state, index, qsParser, queryKeywords) => {
    const { query = "" } = qsParser
      ? qsParser.parse(location.search.slice(1))
      : (qs.parse(location.search.slice(1)) as FilterQueryParams);

    trackOfferFilterEvent("Product List Filtered", state);

    if (isDefaultRoute(state)) {
      return "";
    }

    const queryParameters: FilterQueryParams = {};

    if (state.query) {
      queryParameters.query = encodeURIComponent(state.query);
    }

    if (state.sortBy && state.sortBy !== index) {
      queryParameters.sortBy = getSortSlug(state.sortBy);
    }

    if (state.page !== 1) {
      queryParameters.page = state.page;
    }

    if (state.toggle) {
      queryParameters.toggle = Object.keys(state.toggle).reduce((acc, key) => {
        if (state.toggle[key]) {
          return {
            ...acc,
            [key]: state.toggle[key],
          };
        }
        return acc;
      }, {});
    }

    if (state?.refinementList) {
      RefinementFacets.forEach(({ attribute, urlKey }) => {
        if (state.refinementList[attribute]) {
          queryParameters[urlKey] = state.refinementList[attribute].map(
            encodeURIComponent
          );
        }
      });
    }

    if (state?.multiRange) {
      MultiRangeFacets.forEach(({ attribute, urlKey }) => {
        if (state.multiRange[attribute]) {
          queryParameters[urlKey] = encodeURIComponent(
            state.multiRange[attribute]
          );
        }
      });
    }

    if (state?.refinementList) {
      FilterOnlyFacets.forEach(({ attribute, urlKey }) => {
        if (state.refinementList[attribute]) {
          if (state.refinementList[attribute]?.includes("true")) {
            return;
          }
          queryParameters[urlKey] = state.refinementList[attribute].map(
            encodeURIComponent
          );
        }
      });
    }

    if (!state.query && queryKeywords) {
      queryParameters.keywords = encodeURIComponent(queryKeywords);
    }

    const queryString = qs.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: "repeat",
    });

    const isSearchGlobal = localStorage.getItem("isSearchGlobal") === "true";
    const hasSpiritsPath = location.pathname.includes("/spirits");

    const stateToUrl =
      (queryParameters?.query && queryParameters?.query?.length > 0) ||
      (queryParameters?.keywords && queryParameters?.keywords?.length > 0)
        ? `${
            hasSpiritsPath && WHISKYMOFO_HEADER_ENABLED ? "/spirits" : "/wines"
          }${queryString}`
        : `${location.pathname}${queryString}`;

    // if (location.pathname.includes("spirits") && !isSearchGlobal) {
    //   stateToUrl =
    //     state?.query?.length > 0
    //       ? `${"/spirits/"}${queryString}`
    //       : `${location.pathname}${queryString}`;
    // }

    if (isSearchGlobal) localStorage.removeItem("isSearchGlobal");
    setCarryOverFilter(queryString);
    return stateToUrl;
  };

  const urlToSearchState = (location, index, qsParser) => {
    let { query = "", sortBy = "", page = 1, toggle = {}, ...params } = qsParser
      ? qsParser.parse(location.search.slice(1))
      : (qs.parse(location.search.slice(1)) as FilterQueryParams);

    if (sortBy === "" || sortBy === "popular") {
      sortBy = index;
    }

    const refinementList = RefinementFacets.reduce(
      (acc, { attribute, urlKey }) => {
        const paramValue = params[urlKey];

        if (!paramValue) return acc;

        const refinement = Array.isArray(paramValue)
          ? paramValue.map(decodeURIComponent)
          : [paramValue].filter(Boolean).map(decodeURIComponent);

        acc[attribute] = refinement;
        return acc;
      },
      {}
    );

    const multiRange = MultiRangeFacets.reduce((acc, { attribute, urlKey }) => {
      const paramValue = params[urlKey];

      if (!paramValue) {
        return acc;
      }

      const refinement = decodeURIComponent(paramValue);

      acc[attribute] = refinement;
      return acc;
    }, {});

    const FilterOnly = FilterOnlyFacets.reduce((acc, { attribute, urlKey }) => {
      const paramValue = params[urlKey];

      if (!paramValue) return acc;

      const refinement = Array.isArray(paramValue)
        ? paramValue.map(decodeURIComponent)
        : [paramValue].filter(Boolean).map(decodeURIComponent);

      acc[attribute] = refinement;
      return acc;
    }, {});

    const urlToState = {
      query: decodeURIComponent(query),
      originalQuery: decodeURIComponent(query),
      hasResult: false,
      page,
      sortBy: getSortValue(sortBy, index),
      toggle,
      refinementList: { ...refinementList, ...multiRange, ...FilterOnly },
      multiRange,
    };

    return urlToState;
  };

  const ctx: InstantSearchContextInterface = {
    ...initialState,
    carryOverFilter,
    setCarryOverFilter,
    tierFilter,
    currentFilterGroup,
    toggleFilterGroup,
  };

  const INDEX_NAME = index;

  const routing = {
    router: history({
      writeDelay: 100,
      cleanUrlOnDispose: false,
      parseURL: ({ qsModule, location }) => {
        const queryKeywords = isBrowser()
          ? localStorage.getItem("queryKeywords")
          : null;
        const parsedState = urlToSearchState(location, INDEX_NAME, qsModule);
        const urlToState = {
          [INDEX_NAME]: parsedState,
        };

        if (
          queryKeywords &&
          queryKeywords?.length > 0 &&
          parsedState?.query &&
          parsedState?.query.length > 0
        ) {
          localStorage.removeItem("queryKeywords");
        }

        return urlToState;
      },
      createURL: ({ qsModule, location, routeState }) => {
        const queryKeywords = isBrowser()
          ? localStorage.getItem("queryKeywords")
          : null;
        const indexState = routeState[INDEX_NAME] || {};
        const withSortBy = { ...indexState }; // we need to remove the sortBy for it to appear in the URL
        const stateToUrl = createURL(
          location,
          withSortBy,
          INDEX_NAME,
          qsModule,
          queryKeywords
        );

        if (
          (withSortBy.query && withSortBy.query.length > 0) ||
          (queryKeywords && queryKeywords?.length > 0)
        ) {
          const timer = setTimeout(() => {
            navigate(stateToUrl);
            // if (queryKeywords && queryKeywords?.length > 0)
            //   localStorage.removeItem("queryKeywords");
            clearTimeout(timer);
          }, 100);
        }

        return stateToUrl;
      },
    }),
  };

  const clearuiStateQuery = (obj, newValue = "") => {
    for (const key in obj) {
      if (key === "query") {
        obj[key] = newValue; // Override with newValue
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        clearuiStateQuery(obj[key], newValue); // Recursively traverse nested objects
      }
    }
    return obj;
  };

  const onStateChange = ({ uiState, setUiState }) => {
    setUiState(uiState);
  };

  return (
    <InstantSearch
      indexName={index || ""}
      searchClient={searchClient}
      routing={routing}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
      onStateChange={onStateChange}
      insights={true}
    >
      <InstantSearchContext.Provider value={ctx}>
        {children}
      </InstantSearchContext.Provider>
    </InstantSearch>
  );
};
